<template>
  <v-row justify="center">
    <v-dialog
      v-model="isActive"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Stand: {{ stand.code.toUpperCase() }}</span>
        </v-card-title>
        <v-card-text>
          <div class="row">
            <div class="col-12 col-md-6">
              <span class="font-bold">Espacio:</span> {{ stand.placeName }}
            </div>
            <div class="col-12 col-md-6">
              <span class="font-bold">Categoria:</span> {{ stand.categoryName }}
            </div>
            <div class="col-12 col-md-6">
              <span class="font-bold">Área:</span> {{ stand.area }} m2
            </div>
            <div class="col-12 col-md-6" v-if="stand.status === 'available'">
              <span class="font-bold">Estado:</span> <span class="font-weight-bold green--text">Disponible</span>
            </div>
            <div class="col-12 col-md-6" v-else>
              <span class="font-bold">Estado:</span> <span class="red--text">Reservado</span>
            </div>
            <div class="col-12 col-md-6" v-if="stand.status === 'booked'">
              <span class="font-bold">Compañia: </span>{{ stand.bookingCompany }}
            </div>
            <div class="col-12" v-if="stand.decription">
              <span class="font-bold">Descripción:</span> {{ stand.description }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="stand.status === 'available'"
            color="primary"
            text
            @click="$emit('onBook')"
          >
            Solicitar Stand
          </v-btn>
          <v-btn
            color="red"
            text
            @click="$emit('onClose')"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

export default {
  name: 'StandDetailModal',
  props: {
    isActive: {
      default: false,
      type: Boolean
    },
    stand: {
      default: null,
      type: Object
    }
  }
}
</script>
