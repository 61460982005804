<template>
  <div>
    <div class="row">
      <div class="col-12" v-if="selectedStands.length">
        <stands-table :selected-stands="selectedStands" :show-values="true"
                      :cop-payment-currency="model.copPaymentCurrency"/>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <validation-provider v-slot="{ errors }" name="Forma de pago" rules="required">
          <v-select
            vid="paymentMethodId"
            v-model="model.paymentMethodId"
            :error-messages="errors"
            :items="paymentMethods"
            outlined
            dense
            item-text="name"
            item-value="id"
            label="Forma de pago"
          ></v-select>
        </validation-provider>
      </div>
      <div class="col-12 col-md-6">
        <validation-provider v-slot="{ errors }" name="Confirmación de forma de pago" rules="required">
          <v-select
            vid="confirmationPaymentMethodId"
            v-model="model.confirmationPaymentMethodId"
            :error-messages="errors"
            :items="paymentMethods"
            outlined
            dense
            item-text="name"
            item-value="id"
            label="Confirmación de forma de pago"
          ></v-select>
        </validation-provider>
      </div>
      <div class="col-12 col-md-6">
        <h3 v-if="model.paymentMethodId">Valor de las cuotas</h3>
        <v-simple-table v-if="model.paymentMethodId" dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                Cuota #
              </th>
              <th class="text-left">
                Porcentaje
              </th>
              <th class="text-left">
                Valor
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="(plan, index) in paymentPlans"
              :key="plan.id"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ plan.percentage * 100 }}%</td>
              <td>
                {{ model.copPaymentCurrency ? '$' : '' }}
                {{ plan.percentage * totalPrice | currency }}
                {{ !model.copPaymentCurrency ? 'USD' : '' }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>

      <div class="col-12 ml-2">
        <ValidationProvider v-slot="{ errors }" name="Términos y condiciones de uso de la información" rules="required">
          <v-checkbox v-model="model.termsAndConditions" :error-messages="errors"
                      :rules="[v => v || 'Debes aceptar los términos y condiciones de uso de la información para continuar']">
            <div slot='label'>
              Acepta los
              <a @click.stop href="https://fise.co/wp-content/uploads/2020/06/habeas-data-terminos-de-informacion.pdf"
                 target="_blank">
                Términos y condiciones de uso de la información.
              </a>
            </div>
          </v-checkbox>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
import StandsTable from '@/views/public/booking/components/StandsTable'
import { PAYMENT_METHODS_URL } from '@/constants/ServicesConstants'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'

export default {
  components: { StandsTable },
  async created () {
    try {
      this.showLoader()
      await this.getPaymentMethods()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  computed: {
    totalPrice () {
      let totalPrice = 0
      this.selectedStands.forEach((stand) => {
        if (this.model.copPaymentCurrency) {
          totalPrice += stand.copValue
          totalPrice += stand.copTax
        } else {
          totalPrice += stand.usdValue
          totalPrice += stand.usdTax
        }
      })
      return totalPrice
    }
  },
  data () {
    return {
      paymentMethods: [],
      paymentPlans: []
    }
  },
  methods: {
    async getPaymentMethods () {
      const response = await this.get(PAYMENT_METHODS_URL, { params: { fairId: this.fair.id } })
      this.paymentMethods = response.data
    }
  },
  mixins: [
    crudServiceMixin,
    loaderMixin,
    notificationMixin
  ],
  name: 'StandsConfirm',
  props: {
    fair: {
      default: null,
      type: Object
    },
    model: {
      default: null,
      type: Object
    },
    selectedStands: {
      default: () => [],
      type: Array
    }
  },
  watch: {
    'model.paymentMethodId': {
      handler (newValue) {
        const paymentMethod = this.paymentMethods.filter((item) => item.id === newValue)
        this.paymentPlans = paymentMethod[0].plans
      },
      deep: true
    }
  }
}
</script>
