<template>
  <div>
    <banner
      v-if="fair"
      bold-title="Solicitud"
      type="banner--admin"
      :title="` de Stands - ${fair.name}`"
      description="Para realizar tu solicitud de Stands elige en el plano el espacio de tu elección"
    >
    </banner>
    <v-stepper
      class="elevation-0"
      v-if="!showConfirmation"
      v-model="step"
      vertical
    >
      <v-stepper-step :complete="step > 1" step="1">
        Selecciona tus stands
      </v-stepper-step>

      <v-stepper-content step="1">
        <stands-table
          :selected-stands="selectedStands"
          @removeSelectedStand="removeStand"
        />
        <fair-plane
          v-if="showGeneralPlane && planeImageUrl"
          :places="places"
          :plane-image-url="planeImageUrl"
          :showPavilion="showPavilion"
          :pin-icon-url="pinIconUrl"
        />
        <place-plane
          v-if="!showGeneralPlane && userPlaneImageUrl"
          :user-plane-image-url="userPlaneImageUrl"
          :place="place"
          :book-stand="bookStand"
          :selected-stands="selectedStands"
          :stands="stands"
          @reloadGeneralPlane="showGeneralPlane = true"
        />
        <v-btn
          color="primary"
          class="mt-4 mr-2 elevation-0 text-none"
          @click="step = 2"
          :disabled="!selectedStands.length"
        >
          Siguiente
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="step > 2" step="2">
        Información general de la empresa
      </v-stepper-step>

      <v-stepper-content step="2">
        <validation-observer ref="formStep2">
          <form novalidate autocomplete="off">
            <company-info v-if="fair" :model="model" :fairId="fair.id" @activeBooking="activeBookingMessage"/>
          </form>
        </validation-observer>

        <v-btn
          color="primary"
          class="mr-2 elevation-0 text-none"
          @click="validateStep2"
        >
          Siguiente
        </v-btn>
        <v-btn class="elevation-0 text-none" text @click="step = 1">
          Anterior
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="step > 3" step="3">
        Información de contacto
      </v-stepper-step>
      <v-stepper-content step="3">
        <validation-observer ref="formStep3">
          <form novalidate autocomplete="off">
            <contact-info :model="model"/>
          </form>
        </validation-observer>

        <v-btn
          color="primary"
          class="mr-2 elevation-0 text-none"
          @click="validateStep3"
        >
          Siguiente
        </v-btn>
        <v-btn text class="elevation-0 text-none" @click="step = 2">
          Anterior
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="step > 4" step="4">
        Confirmación de la solicitud
      </v-stepper-step>

      <v-stepper-content step="4">
        <validation-observer ref="formStep4">
          <form novalidate autocomplete="off">
            <stands-confirm
              v-if="fair"
              :model="model"
              :selected-stands="selectedStands"
              :fair="fair"
            />
          </form>
        </validation-observer>

        <v-btn
          color="primary"
          class="mr-2 elevation-0 text-none"
          @click="validateStep4"
        >
          Enviar
        </v-btn>
        <v-btn text @click="step = 3" class="elevation-0 text-none">
          Anterior
        </v-btn>
      </v-stepper-content>
    </v-stepper>
    <div v-else>
      <stand-selection-successful></stand-selection-successful>
    </div>
    <confirm-dialog
      :title="'Confirmación'"
      :description="'¿Deseas seleccionar este stand?'"
      @onClose="isConfirmActive = false"
      @onConfirm="addStand"
      :is-active="isConfirmActive"
    />
    <stand-detail-modal
      v-if="selectedStand"
      :stand="selectedStand"
      :is-active="isStandDetailModalActive"
      @onClose="close"
      @onBook="showBookConfirmation"
    />
    <v-dialog v-model="identificationDialog" width="500">
      <v-card>
        <v-card-title class="headline error lighten-2 white--text">
          Error
        </v-card-title>

        <v-card-text>
          <p class="mt-4">
            {{ identificationDialogMessage }}
          </p>
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="identificationDialog = false">
            Entendido
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import {
  PUBLIC_BOOKINGS_URL,
  PUBLIC_FAIRS_URL,
  PUBLIC_PLACE_URL,
  PUBLIC_STAND_URL
} from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import sessionMixin from '@/mixins/sessionMixin'
import FairPlane from '@/views/public/booking/components/FairPlane'
import notificationMixin from '@/mixins/notificationMixin'
import PlacePlane from '@/views/public/booking/components/PlacePlane'
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog'
import CompanyInfo from '@/views/public/booking/components/CompanyInfo'
import ContactInfo from '@/views/public/booking/components/ContactInfo'
import StandsConfirm from '@/views/public/booking/components/StandsConfirm'
import StandsTable from '@/views/public/booking/components/StandsTable'
import StandDetailModal from '@/views/public/booking/components/StandDetailModal'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import Banner from '@/components/banner/Banner'
import StandSelectionSuccessful from '@/views/public/booking/components/StandSelectionSuccessful'

export default {
  components: {
    StandSelectionSuccessful,
    Banner,
    CompanyInfo,
    ConfirmDialog,
    ContactInfo,
    FairPlane,
    PlacePlane,
    StandsConfirm,
    StandDetailModal,
    StandsTable
  },
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (user) {
      localStorage.removeItem('userInfo')
    }
    next()
  },
  async created () {
    const fairSlug = this.$route.params.slug
    try {
      this.showLoader()
      await this.getFairBySlug(fairSlug)
      await this.getPavilions(this.fair.id)
      this.planeImageUrl =
        process.env.VUE_APP_API_IMAGES_URL + this.fair.planeImageUrl
      this.pinIconUrl =
        process.env.VUE_APP_API_IMAGES_URL + this.fair.pinIconUrl
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  data () {
    return {
      fair: null,
      identificationDialog: false,
      identificationDialogMessage: null,
      isConfirmActive: false,
      isStandDetailModalActive: false,
      model: {
        address: '',
        businessName: '',
        CEOEmail: '',
        CEOPhoneExtension: '',
        CEOName: '',
        CEOPositionDetail: '',
        CEOPhone: '',
        cityId: null,
        confirmationPaymentMethodId: null,
        countryId: null,
        departmentId: null,
        email: '',
        firstContactId: null,
        firstContactEmail: '',
        firstContactPhoneExtension: '',
        firstContactMobilePhone: '',
        firstContactName: '',
        firstContactPositionDetail: '',
        firstContactPhone: '',
        identification: '',
        name: '',
        paymentMethodId: null,
        copPaymentCurrency: true,
        phone: '',
        phoneExtension: '',
        secondContactId: null,
        secondContactEmail: '',
        secondContactPhoneExtension: '',
        secondContactMobilePhone: '',
        secondContactName: '',
        secondContactPositionDetail: '',
        secondContactPhone: '',
        termsAndConditions: null,
        webSite: ''
      },
      place: null,
      places: [],
      pinIconUrl: null,
      planeImageUrl: null,
      selectedStands: [],
      selectedStand: null,
      showConfirmation: false,
      showGeneralPlane: true,
      stands: [],
      step: 1,
      userPlaneImageUrl: null
    }
  },
  methods: {
    activeBookingMessage () {
      this.$refs.formStep2.errors.identification.push('La empresa ya tiene una reserva activa en proceso')
    },
    addStand () {
      this.isConfirmActive = false
      this.selectedStands.push(this.selectedStand)
      this.selectedStand = null
    },
    bookStand (stand) {
      const isSelected = this.selectedStands.find(
        (item) => stand.id === item.id
      )
      if (!isSelected) {
        this.selectedStand = stand
        this.isStandDetailModalActive = true
      }
    },
    close () {
      this.isStandDetailModalActive = false
    },
    async create (url, model) {
      try {
        this.showLoader()
        const response = await this.post(url, model)
        if (response && response.status === 201) {
          this.createSuccessNotify()
          return response
        } else {
          this.createFailNotify()
        }
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.invalidFields()
          this.$refs.formStep2.setErrors({ ...error.response.data })
          this.$refs.formStep3.setErrors({ ...error.response.data })
          this.$refs.formStep4.setErrors({ ...error.response.data })
        } else {
          this.createFailNotify()
        }
        throw error
      } finally {
        this.hideLoader()
      }
    },
    async getFairBySlug (fairSlug) {
      const response = await this.getById(PUBLIC_FAIRS_URL, fairSlug)
      this.fair = response.data
    },
    async getPavilions (fairId) {
      const params = { fairId: fairId }
      const response = await this.get(PUBLIC_PLACE_URL + '/all', {
        params: params
      })
      this.places = response.data
    },
    async getStands (placeId) {
      const response = await this.get(PUBLIC_STAND_URL + '/all', {
        params: { placeId: placeId }
      })
      this.stands = response.data
    },
    removeStand (stand) {
      this.selectedStands = this.selectedStands.filter(
        (item) => stand.id !== item.id
      )
    },
    showBookConfirmation () {
      this.isConfirmActive = true
      this.isStandDetailModalActive = false
    },
    async showPavilion (place) {
      this.showGeneralPlane = false
      try {
        this.showLoader()
        await this.getStands(place.id)
        this.place = place
        const userPlaneImageUrl = new Image()
        userPlaneImageUrl.src = process.env.VUE_APP_API_IMAGES_URL + this.place.userPlaneImageUrl
        userPlaneImageUrl.onload = () => {
          this.userPlaneImageUrl = userPlaneImageUrl
        }
        this.hideLoader()
      } catch {
        this.hideLoader()
        this.showError()
      }
    },
    async validateStep2 () {
      const result = await this.$refs.formStep2.validate()
      if (result) {
        this.step = 3
      }
    },
    async validateStep3 () {
      const result = await this.$refs.formStep3.validate()
      if (result) {
        try {
          this.showLoader()
          await this.get(`${PUBLIC_BOOKINGS_URL}/validate-booking-contacts`, {
            params: {
              firstContactEmail: this.model.firstContactEmail,
              firstContactId: this.model.firstContactId,
              secondContactEmail: this.model.secondContactEmail,
              secondContactId: this.model.secondContactId,
              fairId: this.fair.id
            }
          })
          this.step = 4
          this.hideLoader()
        } catch (e) {
          this.hideLoader()
          if (e && e.response && e.response.data.length >= 2) {
            this.showError(e.response.data[1])
          } else {
            this.showError()
          }
        }
      }
    },
    async validateStep4 () {
      const result = await this.$refs.formStep4.validate()
      if (
        this.model.paymentMethodId !== this.model.confirmationPaymentMethodId
      ) {
        this.$refs.formStep4.setErrors({
          'Forma de pago': ['Las formas de pago no coinciden.']
        })
      } else if (!this.model.termsAndConditions) {
        this.$refs.formStep4.setErrors({
          'Términos y condiciones': [
            'Debes aceptar los términos y condiciones para continuar.'
          ]
        })
      } else if (result) {
        this.model.stands = this.selectedStands.map(
          (selectedStand) => selectedStand.id
        )
        this.model.fairId = this.fair.id
        try {
          const response = await this.create(PUBLIC_BOOKINGS_URL, this.model)
          if (response) {
            this.showConfirmation = true
          }
        } catch (error) {
          this.identificationDialog = true
          this.identificationDialogMessage = error.response.data.error
        }
      }
    }
  },
  mixins: [
    crudServiceMixin,
    loaderMixin,
    notificationMixin,
    formOperationsMixin
  ],
  name: 'StandSelection'
}
</script>
